.logo {
	z-index: 11;
}

@media (max-width: 1920px) {
	.logo {
		svg {
			width: 140px;
		}
	}
}