@import '../../../styles/scss/variables.scss';

.button {
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding: 11px 13px;
  border-radius: 99px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid transparent;

  svg {
    transition: 200ms transform ease-in-out;
  }
  @media (min-width: 800px) {
    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
  @media (max-width: 800px) {
    &:active {
      svg {
        transform: translateX(5px);
      }
    }
  }
}

.buttonDark {
  color: $accent-clr;

  svg {
    transition: 200ms transform ease-in-out;
  }

  @media (min-width: 800px) {
    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
  @media (max-width: 800px) {
    &:active {
      svg {
        transform: translateX(5px);
      }
    }
  }
}

.buttonBlack {
  border: 1px solid transparent;
  border-radius: 99px;
  background-color: $dark-clr;
  color: $accent-clr;
  padding: 11px 13px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: border 100ms, background-color 100ms, color 100ms ease-in-out;

  svg {
    transition: fill 100ms ease-in-out;
  }
  @media (min-width: 800px) {
    &:hover {
      border: 1px solid $dark-clr;
      background-color: $accent-clr;
      color: $dark-clr;

      svg {
        fill: $dark-clr;
      }
    }
  }
  @media (max-width: 800px) {
    &:active {
      border: 1px solid $dark-clr;
      background-color: $accent-clr;
      color: $dark-clr;

      svg {
        fill: $dark-clr;
      }
    }
  }
}

.buttonBlackDark {
  color: $dark-clr;
  background-color: $accent-clr;
  transition: border 100ms, background-color 100ms, color 100ms ease-in-out;

  @media (min-width: 800px) {
    &:hover {
      border: 1px solid $accent-clr;
      background-color: $dark-clr;
      color: $accent-clr;

      svg {
        fill: $accent-clr;
      }
    }
  }
  @media (max-width: 800px) {
    &:active {
      border: 1px solid $accent-clr;
      background-color: $dark-clr;
      color: $accent-clr;

      svg {
        fill: $accent-clr;
      }
    }
  }
}

.buttonBlackLarge {
  padding: 16px 19px 16px 16px;
}

@media (max-width: 1200px) {
  .button {
    font-size: 12px;
  }

  .buttonBlack {
    font-size: 12px;
  }
  .buttonBlackLarge {
    padding: 12px 19px 12px 16px;
  }
}

@media (max-width: 360px) {
  .button {
    font-size: 10px;
  }

  .buttonBlack {
    font-size: 10px;
  }

  .buttonBlackLarge {
    padding: 6px 15px 6px 12px;
  }
}
