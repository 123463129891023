.currencyTable {
  background-color: #fff;
  border-radius: 30px;
  padding: 15px 50px;
  font-size: 20px;
  line-height: 24px;
}

.row {
  display: grid;
  grid-template: 1fr / 40% 20% 20% 20%;
  padding: 25px 0;
  border-bottom: 1px solid #f2f2f2;
  align-items: center;
}

.text {
  text-align: center;
	font-size: 20px;
	line-height: 24px;
  margin: 42px 0;
}

.name {
  display: flex;
  align-items: center;
  gap: 15px;

  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
      font-size: 15px;
      line-height: 17px;
      font-family: 'Recklessneue';
    }
  }
}

@media (max-width: 1920px) {
  .currencyTable {
    font-size: 18px;
    line-height: 22px;
  }

}
@media (max-width: 1600px) {
  .currencyTable {
    font-size: 16px;
    line-height: 20px;
  }

}
@media (max-width: 1300px) {
  .currencyTable {
    font-size: 14px;
    line-height: 18px;
  }

  .row {
    grid-template: 1fr / 1fr 0.5fr 1fr 1fr;
    column-gap: 20px;
  }

  .name div span {
    font-size: 13px;
  }
}

@media (max-width: 1000px) {
  .currencyTable {
    font-size: 12px;
    line-height: 14px;
  }

  .row {
    column-gap: 10px;
    grid-template: 1fr / 1.5fr 0.5fr 1fr 1fr;
  }

  .name div span {
    font-size: 10px;
  }

	.text {
		font-size: 15px;
		line-height: 20px;
		margin: 35px 0;
	}
}

@media (max-width: 735px) {
  .currencyTable {
    font-size: 10px;
    line-height: 15px;
    padding: 15px 20px;

    img {
      width: 25px;
      height: 25px;
    }
  }
  .row {
    column-gap: 10px;
    grid-template: 1fr / 2fr 1fr 1fr 1fr;
  }

  .name div span {
    font-size: 8px;
  }
}
@media (max-width: 500px) {
  .currencyTable {
    padding: 15px 10px;
    font-size: 8px;
    line-height: 10px;

    img {
      width: 23px;
      height: 23px;
    }
  }

  .name {
    gap: 7px;
    div {
      gap: 0;
      span {
        font-size: 7px;
      }
    }
  }

	.text {
		font-size: 14px;
		line-height: 19px;
	}
}
