.platform {
  background-color: #fff;
  padding: 27px 26px;
}

.subtitle {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 18px;
  display: block;
  margin-bottom: 12px;
  @media (max-width: 1920px) {
    font-size: 12px;
  }
}

.additional {
  margin-left: 5px;
}

.title {
	font-family: 'Recklessneue';
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
	margin: 0 0 23px 0;

  @media (max-width: 1920px) {
    font-size: 35px;
    line-height: 30px;
  }
}

.featuresWrapper {
  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  column-gap: 25px;
}

.feature {
  background-color: #f8f8f8;
  border-radius: 30px;
  padding: 29px 25px;
}

.feature__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 0;
  margin: 2px 0 11px 0;
  display: inline-block;

  @media (max-width: 1920px) {
    font-size: 17px;
    line-height: 21px;
  }
}

.feature__descr {
  font-size: 20px;
  line-height: 24px;
  padding: 0;
  margin: 0;

  @media (max-width: 1920px) {
    font-size: 16px;
    line-height: 20px;
  }
}


@media (max-width: 1600px) {
	.feature {
		padding: 20px 25px;
	}

	.feature__title {
		font-size: 14px;
		line-height: 24px;
		margin: 2px 0 5px 0;
	}
	
	.feature__descr {
		font-size: 13px;
		line-height: 16px;
	}

	.title {
		font-size: 30px;
		line-height: 25px;
	}
}

@media (max-width: 1400px) {
	.featuresWrapper {
		grid-template: 1fr 1fr / repeat(2, 1fr);
		column-gap: 25px;
		row-gap: 25px;
	}
}
@media (max-width: 700px) {

	.title {
		font-size: 25px;
		line-height: 35px;
	}

	.featuresWrapper {
		grid-template: repeat(4, 1fr) / 1fr;
		column-gap: 25px;
		row-gap: 25px;
	}
}

@media (max-width: 400px) {
	.platform {
		padding: 27px 15px;
	}
	.feature {
		padding: 20px 16px;
	}

	.feature__title {
		font-size: 12px;
		line-height: 18px;
	}
	
	.feature__descr {
		font-size: 10px;
		line-height: 14px;
	}
}