@import '../../styles/scss/variables.scss';

.banner {
  background-color: $dark-clr;
  border-radius: 30px;
  color: $accent-clr;
  padding: 78px;
	padding-bottom: 70px;
  text-align: center;

  @media (max-width: 1920px) {
    padding: 50px;
  }
  @media (max-width: 1000px) {
    padding: 50px 10px;
  }
}

.title {
	font-family: 'Recklessneue';
  font-weight: 400;
  font-size: 82px;
  line-height: 95px;
	margin: 0;

  @media (max-width: 1920px) {
    font-size: 50px;
    line-height: 70px;
  }

  @media (max-width: 1100px) {
    font-size: 30px;
    line-height: 50px;
  }

  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 40px;
  }

  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 30px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.buttons {
  margin-top: 32px;
  display: flex;
  justify-content: center;

  @media (max-width: 1920px) {
    margin-top: 20px;
  }
}
