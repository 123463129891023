@import '../../styles/scss/variables.scss';

.tipBlock {
	background-color: $accent-clr;
}

.tipText {
	padding: 9px;
	margin: 0;
	text-align: center;
	font-size: 15px;
	line-height: 18px;
	font-weight: 400;

	position: relative;
	right: -2px;
}

@media (max-width: 1920px) {
	.tipText {
		font-size: 13px;
		line-height: 15px;
	}
}
@media (max-width: 1600px) {
	.tipText {
		font-size: 10px;
		line-height: 15px;
		padding: 5px;
	}
}
@media (max-width: 1100px) {
	.tipBlock {
		display: none;
	}
}