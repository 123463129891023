.promoPricing {
  position: relative;
  padding: 100px 0;
	text-align: center;

  p {
    max-width: 58%;
    margin: 9px auto 18px;
  }
}

.title {
  font-family: 'Recklessneue';
  font-weight: 400;
	font-size: 80px;
	line-height: 90px;
  margin: 0;
}

.text {
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.buttons {
	display: flex;
	justify-content: center;
}


@media (max-width: 1720px) {
  .title {
    font-size: 50px;
    line-height: 70px;
    margin: 0;
  }

  .text {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
  }
}
@media (max-width: 1200px) {
  .promoPricing {
    position: relative;
    padding: 80px 0 30px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
  }

  .text {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 800px) {
  .promoPricing {
    position: relative;
    padding: 40px 0 0;
    text-align: center;

    p {
      max-width: 100%;
      width: 80%;
      margin: 10px auto 20px;
    }
  }
  .title {
    font-size: 40px;
    line-height: 45px;
  }

  .text {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 500px) {
  .promoPricing {
    padding: 50px 0 30px;

    p {
      width: 90%;
    }
  }
  .title {
    font-size: 30px;
    line-height: 40px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 370px) {

  .title {
    font-size: 25px;
    line-height: 40px;
  }
}
