.promoMain {
  position: relative;
  padding: 140px 0 90px;

  p {
    max-width: 49.4%;
    margin: 9px 0 18px;
  }
}

.container {
	position: relative;
	left: 50%;
}

.title {
  font-family: 'Recklessneue';
  font-weight: 400;
  font-size: 80px;
  line-height: 90px;
  margin: 0;
}

.text {
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.dashboard {
  position: absolute;
  width: 49.5%;
  top: 50%;
  transform: translateY(calc(-50% + 20px));
  left: -52px;
}

@media (max-width: 1720px) {
  .title {
    font-size: 50px;
    line-height: 70px;
    margin: 0;
  }

  .text {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
  }
}
@media (max-width: 1200px) {
  .promoMain {
    position: relative;
    padding: 80px 0 30px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
  }

  .text {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 800px) {
  .promoMain {
    position: relative;
    padding: 40px 0 0;
    text-align: center;

    p {
      max-width: 100%;
      width: 80%;
      margin: 10px auto 20px;
    }
  }

	.container {
		position: static;
		left: auto;
	}

  .dashboard {
    position: static;
    width: 100%;
    transform: none;
    top: auto;
    right: auto;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 40px;
    line-height: 45px;
  }

  .text {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 600px) {
  .promoMain {

    p {
      width: 90%;
    }
  }


  .text {
    font-size: 15px;
    line-height: 18px;
		margin-bottom: 20px;
  }
}
