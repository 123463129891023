@import '../../styles/scss/variables.scss';

.footer {
  margin: 75px 0;
  border-radius: 30px;
  background-color: $accent-clr;
  color: $dark-clr;
  padding: 46px 40px;
  font-size: 14px;
  line-height: 17px;

  @media (max-width: 1920px) {
    font-size: 13px;
    line-height: 16px;
  }
  @media (max-width: 1600px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 100px;

  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 40px;
  }
}

.main {
  max-width: 700px;
  flex-shrink: 5;
}

.policy {
  margin: 41px 0 0;
}

.nav {
  display: flex;
  gap: 94px;
  flex-shrink: 1;

  @media (max-width: 580px) {
    flex-direction: column;
    gap: 20px;
  }
}

.nav__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav__item {
  margin: 0 0 11px 0;
  text-transform: capitalize;

  @media (min-width: 800px) {
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 800px) {
    &:active {
      text-decoration: underline;
    }
  }
}
