@import '../../../styles/scss/variables.scss';

.titleWrapper {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: $dark-clr;

  background-image: url(../../../../public/images/icons/check.svg);
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1920px) {
    width: 17px;
    height: 17px;
  }
}

.title {
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  @media (max-width: 1920px) {
    font-size: 17px;
    line-height: 20px;
  }
}

.descr {
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  max-width: 367px;

  @media (max-width: 1920px) {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 1300px) {
  .title {
    font-size: 14px;
    line-height: 20px;
  }

  .descr {
    font-size: 10px;
    line-height: 14px;
  }

  .icon {
    background-size: 70%;
    width: 13px;
    height: 13px;
  }
}

@media (max-width: 800px) {
  .title {
    font-size: 14px;
    line-height: 20px;
  }

  .descr {
    font-size: 10px;
    line-height: 14px;
  }

  .icon {
    background-size: 70%;
    width: 13px;
    height: 13px;
  }
}