@import '../../styles/scss/variables.scss';

.features {
  background-color: $dark-clr;
  color: $accent-clr;
  padding: 57px 51px 52px;
  margin-top: 71px;
}

.features__text {
  font-family: 'Recklessneue';
  font-size: 40px;
  line-height: 47px;
  padding: 0;
  margin: 0;

  @media (max-width: 1920px) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (max-width: 1400px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.features__list {
  padding: 0;
  margin: 40px 0 0;

  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  column-gap: 8%;

  @media (max-width: 1400px) {
    grid-template: 1fr 1fr / repeat(2, 1fr);
    row-gap: 20px;
  }
}

.features__item {
  list-style-type: none;

  display: flex;
  align-items: center;
  gap: 21px;

  @media (max-width: 1920px) {
    gap: 10px;
  }
}

.features__point {
  font-family: 'Recklessneue';
  font-size: 20px;
  line-height: 22px;

  @media (max-width: 1920px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.features__icon {
  width: 53px;
  height: 53px;
  background-color: $accent-clr;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1600px) {
    width: 43px;
    height: 43px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

@media (max-width: 660px) {
  .features {
    padding: 30px;
  }

  .features__text {
    font-family: 'Recklessneue';
    font-size: 18px;
    line-height: 24px;
  }

  .features__icon {
    width: 24px;
    height: 24px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .features__point {
    font-size: 11px;
    line-height: 18px;
  }
}
@media (max-width: 430px) {
  .features__list {
    grid-template: repeat(4, 1fr) / 1fr;
    row-gap: 0;
  }
}
