@import '../../styles/scss/variables.scss';

.header {
  margin-top: 33px;
  padding: 35px 50px 43px;
  background-color: #fff;
  border-radius: 30px;
  position: relative;
  background-image: url(../../../public/images/header/bg-1.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.headerPricing {
  background-image: url(../../../public/images/header/bg-2.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.headerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__list {
  margin: 0 18px 0 0;
  padding: 0;
  list-style-type: none;

  display: flex;
  gap: 25px;
}

.overflow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background-color: rgba($color: #000000, $alpha: 0.3);
  display: none;
}

.overflowActive {
  display: block;
}

.nav__item {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  position: relative;

  @media (min-width: 800px) {
    &:hover::after {
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 0;
      height: 2px;
      background-color: $accent-clr;
      transition: width 300ms ease-in-out;
    }
  }
}

.authorization {
  display: flex;
  align-items: center;
  gap: 14px;
}
.authorizationMobile {
  display: none;
  align-items: center;
  gap: 14px;
}

.burger {
  width: 35px;
  height: 17px;
  position: relative;
  display: none;
	cursor: pointer;

	div {
		background-color: $dark-clr;
		width: 100%;
		height: 2px;
		position: absolute;
		left: 0;
		transition: transform 200ms ease-in-out;

		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2) {
			top: 50%;
			transform: translateY(-50%);
		}
		&:nth-child(3) {
			bottom: 0;
		}
	}
}

.burgerActive {
  
	div {

		&:nth-child(1) {
			transform: rotate(-45deg) translateY(11px);
		}
		&:nth-child(2) {
			opacity: 0;
		}
		&:nth-child(3) {
			transform: rotate(45deg) translateY(-11px);
		}
	}
}

@media (max-width: 1100px) {
  .burger {
    display: block;
    z-index: 10;
  }

  .authorization {
    display: none;
  }

  .authorizationMobile {
    display: flex;
  }

  .nav {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 10px 25px;
    background-color: #fff;
    width: 100%;
    border-radius: 30px;
    z-index: 10;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: max-height 200ms, padding 200ms ease-in-out;
  }

  .navActive {
    padding: 100px 10px 25px;
    max-height: 400px;
  }

  .nav__list {
    margin: 0 0 30px 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .nav__item {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }
}

@media (max-width: 650px) {
  .header {
    padding: 30px;
  }

  .burger {
    width: 25px;
		height: 13px;
  }

	.burgerActive {
		div {

			&:nth-child(1) {
				transform: rotate(-45deg) translateY(8px);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: rotate(45deg) translateY(-8px);
			}
		}
	}
}
@media (max-width: 500px) {
  .header {
    padding: 30px 20px 10px;
  }
}
