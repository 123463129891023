@import '../../styles/scss/variables.scss';

.statistics {
  background-color: $accent-clr;
  padding: 75px 130px;

  @media (max-width: 1920px) {
    padding: 55px 110px;
  }
}

.wrapper {
  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  column-gap: 6.63%;
}

.title {
  font-family: 'Recklessneue';
  font-size: 36px;
  line-height: 40px;
  margin: 0 0 14px 0;
  display: block;

  @media (max-width: 1920px) {
    font-size: 30px;
    line-height: 30px;
  }
}

.text {
  font-size: 20px;
  line-height: 24px;
  margin: 0;

  @media (max-width: 1920px) {
    font-size: 17px;
    line-height: 20px;
  }
}

@media (max-width: 1600px) {
  .title {
    font-size: 25px;
    line-height: 25px;
  }

  .text {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 1400px) {
  .wrapper {
    display: grid;
    grid-template: 1fr 1fr / repeat(2, 1fr);
    row-gap: 50px;
  }
}
@media (max-width: 1100px) {
  .statistics {
    padding: 50px 70px;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
  }

  .text {
    font-size: 13px;
    line-height: 16px;
  }
}
@media (max-width: 600px) {
  .statistics {
    padding: 30px;
  }
}
@media (max-width: 450px) {
  .wrapper {
    grid-template: repeat(4, 1fr) / 1fr;
    row-gap: 30px;
  }

  .title {
    margin: 0 0 8px 0;
  }
}
