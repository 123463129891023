.reviews {
	background-color: #FFF;
	border-radius: 30px;
	padding: 75px;
}

.wrapper {
	max-width: 1340px;
	margin: 0 auto;
}

.feedbackWrapper {
	margin-top: 73px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 50px;
}

@media (max-width: 1600px) {
	.feedbackWrapper {
		gap: 30px;
	}
}
@media (max-width: 1400px) {
	.reviews {
		padding: 70px 40px;
	}
}

@media (max-width: 1200px) {
	.feedbackWrapper {
		grid-template-columns: 1fr;
	}
}
@media (max-width: 600px) {
	.reviews {
		padding: 20px;
	}
}