@mixin font-face($font-family, $url, $weight) {
	@font-face {
		font-family: $font-family;
		src: 
			local($font-family),
			url('#{$url}.woff2') format('woff2'),
			url('#{$url}.woff') format('woff'),
			url('#{$url}.ttf') format('ttf');
		font-weight: #{$weight};
		font-display: swap;
		font-style: normal;
	}
}

@include font-face("Rational", "../../../public/fonts/rational-book/rational-book", '400');
@include font-face("Rational", "../../../public/fonts/rational-book/rational-medium", '500');
@include font-face("Rational", "../../../public/fonts/rational-book/rational-semibold", '600');
@include font-face("Recklessneue", "../../../public/fonts/recklessneue-book/recklessneue-book", '400');