@import '../../../styles/scss/variables.scss';

.about__link {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: #f8f8f8;
  border-radius: 99px;
  padding: 16px 18px;
  margin: 31px 0 0 0;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

  svg {
    transition: fill 150ms ease-in-out;
  }

  @media (min-width: 800px) {
    &:hover {
      background-color: $dark-clr;
      color: $accent-clr;

      svg {
        fill: $accent-clr;
      }
    }
  }
  @media (max-width: 800px) {
    &:active {
      background-color: $dark-clr;
      color: $accent-clr;

      svg {
        fill: $accent-clr;
      }
    }
  }

  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 600px) {
    font-size: 10px;

    svg {
      width: 12px;
    }
  }
}
