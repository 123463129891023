@import '../../styles/scss/variables.scss';

.about {
  background-color: #fff;
  padding: 60px 113px 70px;
  position: relative;
  display: grid;
	grid-template-columns: 58% auto;
	column-gap: 70px;
}

.aboutSecond {
	padding: 59px 96px 80px;
	grid-template-columns: auto 53.6%;
}

.aboutThird {
	padding: 63px 88px 72px;
	grid-template-columns: 53% auto;
}

.picture {
	position: relative;

	img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
		object-position: center;

		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

.title {
  font-family: 'Recklessneue';
  font-weight: 400;
  font-size: 78px;
  line-height: 87px;
  margin: 14px 0 21px 0;
}

.descr {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 30px 0;
}

.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 100px;
  row-gap: 30px;
	max-width: 832px;
}

@media (max-width: 1920px) {
	.title {
		font-size: 55px;
		line-height: 70px;
		margin: 0 0 21px 0;
	}
	
	.descr {
		font-size: 18px;
		line-height: 22px;
		margin: 0 0 30px 0;
	}
}

@media (max-width: 1650px) {

	.title {
		font-size: 35px;
		line-height: 45px;
		margin: 0 0 10px 0;
	}
	
	.descr {
		font-size: 16px;
		line-height: 18px;
		margin: 0 0 30px 0;
	}

	.features {
		column-gap: 50px;
	}
}

@media (max-width: 1400px) {
	.about {
		padding: 50px;
	}

	.title {
		font-size: 28px;
		line-height: 35px;
	}
	
	.descr {
		font-size: 12px;
		line-height: 15px;
	}
}

@media (max-width: 900px) {
	.about {
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
		row-gap: 50px;
	}

	.picture {
		img {
			position: static;
			top: auto;
			transform: none;
			margin: 0 auto;
			display: block;
		}
	}
}

@media (max-width: 620px) {
	.features {
		grid-template-columns: 1fr;
	}
}

@media (max-width: 500px) {
	.about {
		padding: 30px;
	}
}