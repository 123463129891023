.starRating {
  display: flex;
  gap: 7px;
}

.feedbackCard {
  background-color: #f8f8f8;
  border-radius: 30px;
  padding: 45px;
}

.title {
  font-family: 'Recklessneue';
  font-size: 24px;
  line-height: 27px;
	margin: 25px 0 48px;
	display: block;
}

.review {
  font-size: 16px;
  line-height: 19px;
	margin: 48px 0 55px;
}

.author {
  font-size: 16px;
  line-height: 19px;
}

@media (max-width: 1600px) {

	.feedbackCard {
		padding: 45px 30px;
	}

	.title {
		font-size: 20px;
		line-height: 24px;
		margin: 25px 0 40px;
	}
	
	.review {
		font-size: 14px;
		line-height: 19px;
		margin: 40px 0 55px;
	}
	
	.author {
		font-size: 14px;
		line-height: 19px;
	}
}

@media (max-width: 500px) {
	.starRating {
		gap: 4px;
		img {
			width: 20px;
		}
	}

	.feedbackCard {
		padding: 35px 20px;
	}

	.title {
		font-size: 18px;
		line-height: 24px;
		margin: 20px 0 30px;
	}
	
	.review {
		font-size: 12px;
		line-height: 17px;
		margin: 30px 0 30px;
	}
	
	.author {
		font-size: 12px;
		line-height: 19px;
	}
}