@import '../../../styles/scss/variables.scss';

.faqBlock {
  background-color: #fff;
  border-radius: 30px;
  padding: 28px 70px 26px 30px;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 400ms ease-in-out, color 100ms ease-in-out, transform 200ms ease-in-out;

  @media (min-width: 800px) {
    &:hover {
      transform: translateY(-5px);
    }
  }
}

.faqBlockActive {
  background-color: $dark-clr;
  color: $accent-clr;

  .answer {
    max-height: 200px;
  }

  .openBtn {
    &::before,
    &::after {
      background-color: $accent-clr;
    }

    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.question__text {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: 0;

  @media (max-width: 1920px) {
    font-size: 20px;
    line-height: 20px;
  }
  @media (max-width: 1600px) {
    font-size: 16px;
    line-height: 18px;
  }
  @media (max-width: 450px) {
    font-size: 13px;
    line-height: 17px;
  }
}

.answer {
  overflow: hidden;
  transition: max-height 2s cubic-bezier(0.22, 1, 0.36, 1);
  max-height: 0;
}

.answer__text {
  font-size: 18px;
  line-height: 22px;
  max-width: 1350px;
  margin: 15px 0 0 0;
  @media (max-width: 1920px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.openBtn {
  border: none;
  background-color: transparent;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 3px;
    background-color: $dark-clr;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: background-color 100ms ease-in-out;
  }

  &::after {
    transform: translateY(-50%) rotate(90deg);
    transition: transform 150ms ease-in-out;
  }
}
