@import 'variables.scss';

* {
  box-sizing: border-box;
  font-family: 'Rational';
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: #f8f8f8;
  color: $dark-clr;
  overflow-x: hidden;
  min-width: 320px;
}

section {
  border-radius: 30px;
  margin: 75px 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.wrapper {
  max-width: 1780px;
  margin: 0 auto;
  padding: 0 70px;
  box-sizing: content-box;

  @media (max-width: 1600px) {
    padding: 0 50px;
  }
  @media (max-width: 1000px) {
    padding: 0 20px;
  }
}

.underlined {
  text-decoration: underline;
  font-family: inherit;
}

.underlined-custom {
  font-family: inherit;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    background-color: $accent-clr;
    position: absolute;
    bottom: 10%;
    width: 100%;
    height: 17%;
    z-index: -1;
  }
}

// .underlined-over {
// 	font-family: inherit;
// 	position: relative;
// 	&::before {
// 		content: '';
// 		position: absolute;
// 		width: 100%;
// 		height: 0.097em;
// 		background-color: $accent-clr;
// 		bottom: 7%;
// 	}
// }

.bolded {
  font-weight: 500;
}

.rational {
  font-family: 'Rational';
}

.recklessneue {
  font-family: 'Recklessneue';
}

.title {
  font-family: 'Recklessneue';
  font-weight: 400;
  font-size: 64px;
  line-height: 72px;
	margin: 0;
}

.text {
  font-size: 24px;
  line-height: 29px;
	margin: 0;
}

@media (max-width: 1920px) {
  .title {
    font-size: 50px;
    line-height: 60px;
  }

  .text {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 1500px) {
  .title {
    font-size: 35px;
    line-height: 52px;
  }

  .text {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 600px) {
  .title {
    font-size: 28px;
    line-height: 36px;
  }

	.text {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 400px) {
  .title {
    font-size: 24px;
    line-height: 30px;
  }

	.text {
    font-size: 12px;
    line-height: 16px;
  }
}
